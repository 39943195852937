#div-algunsclientes-root {
    text-align: center;
    min-height: 95vh;
}

    #algunsclientes-div-img {
        background-image: linear-gradient(90deg, rgba(221, 221, 221, 0.438) 0%, rgba(252, 239, 0, 0.85) 0%, rgba(0, 78, 10, 0.85) 100%), url('../imagens/atopeca02.jpg') ;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 250px;
    }
    #algunsclientes-div-img-titulo {
        margin-top: -80px;
        background-color: white;
        width: 700px;
        text-align: center;
        display: inline-block;
        box-shadow: rgb(156, 156, 156) 5px 10px 15px;
        border-radius: 7px;
        color: green;
        font-size: 30pt;
        font-family: 'Montserrat';
        font-weight: 700;
        border-top: solid 8px yellow;
    }

    .div-info-algunsclientes-line {
        width: 100%;
        margin-top: 50px;
        padding-bottom: 50px;
        padding-top: 20px;
    }

    .div-info-algunsclientes-line-color {
        background-color: rgba(107, 255, 255, 0.151);
    }

    .div-info-algunsclientes-low-left {
        width: 38%;
        max-width: 38%;
        text-align: left;
        padding-left: 30px;
        font-family: 'Montserrat';
        display: inline-block;
        
        vertical-align: middle;
    }
        h1 {
            font-family: 'Montserrat';
        }
        .list-check-algunsclientes {
            margin-bottom: 12px;
        }
        .list-check-algunsclientes > img{
            vertical-align: middle;
            margin-right: 15px;
        }

    .div-info-algunsclientes-low-right {
        max-width: 55%;
        width: 55%;
        text-align: right;
        align-items: right;
        display: inline-block;
        vertical-align: middle;
        padding-right: 50px;
    }
        
        
        .imgens-lines {
            width: 680px;
            height: 400px;
            vertical-align: middle;
            border-radius: 20px;
            display: inline-block;
            
            object-fit: cover;
        }
        .imgens-lines-box {
            box-shadow: 30px 30px 0px 5px rgba(0, 71, 0, 0.788);
        }
        
        #imgens-lines-pc {
            width: 800px;
            height: 600px;
            object-fit: fill;
            margin-right: -30px;
            border-bottom-right-radius: 40px;
        }

        #imgens-lines-dre {
            width:  100%;
            height: 300px;
            object-fit: fill;
        }
        #algunsclientes-img-abc {
            width: auto;
            height: 400px;
            object-fit: fill;
        }

        .div-check-algunsclientes-img, .div-check-algunsclientes-label {
            display: inline-block;
            vertical-align: middle;
        }

        .div-check-algunsclientes-label {
            width: 90%;
            margin-left: 15px;
        }

        #div-carousel {
            max-height: 200px;
            margin-top: 30px;
           
            
            box-shadow: rgb(156, 156, 156) 5px 10px 15px;
            padding: 15px;
            border-radius: 10px;
            max-width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap; 
        }

        .img-clientes {
            height: 600px;
            width: auto;
            object-fit: contain;
            
            
        }

        .legend {
            background-color: green;
        }

        .div-clientes {
            float: left;
            width: 250px;
        }
        
        .img-alguns-clientes-cliente {
            width: 200px;
            height: 200px;
            object-fit: cover;
            border-radius: 100px;
        }

        #testDiv {
            width: 98%;
            display: flex;
            overflow-x: auto;
            padding: 15px;
            padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
    scroll-behavior: smooth;
    margin-top: 50px;
            text-align: center;

          }
        #testDiv::-webkit-scrollbar{
           display: none;
        }
          
          .testimgdiv {
            margin: 10px;
            height: 400px;
            flex: 0 0 auto;
            text-align: left;
          }
          .testimg {
            width: auto;
            height: 280px;
            object-fit: cover;
            border-radius: 10px;
          }
          .car-prox-ant {
            position: absolute;
            vertical-align: center;
            margin-top: 60px;
            background-color: rgb(206, 206, 206, 0.5);
            font-size: 30pt;
            width: 80px;
            border-radius: 110px;
            line-height: 1px;
            color: white;
            font-family: 'Montserrat';
            
          }
          .car-prox-ant:hover {
            background-color: rgb(206, 206, 206, 0.8);
            cursor: pointer;
          }
          .car-prox-ant:hover > p {
            cursor: pointer;
            
          }
          #seta-ant {
            left: 1vw
          }

          #seta-pro {
            left: 93vw;
            
          }

          .testimgdiv {
            font-size: 15pt;
            font-family: 'Montserrat';
          }
@media screen and (max-width: 900px) {
    #algunsclientes-div-img-titulo{
        width: 90%;
        max-width: 100%;
    }
    #testDiv {
        width: 90%;
    }

    #seta-pro {
        left: 78vw;
    }
}
#div-autopecas-root {
    text-align: center;
    
}

    #autopecas-div-img {
        background-image: linear-gradient(90deg, rgba(221, 221, 221, 0.438) 0%, rgba(252, 239, 0, 0.85) 0%, rgba(0, 78, 10, 0.85) 100%), url('../imagens/atopeca01.jpg') ;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 250px;
    }
    #autopecas-div-img-titulo {
        margin-top: -80px;
        background-color: white;
        width: 700px;
        text-align: center;
        display: inline-block;
        box-shadow: rgb(156, 156, 156) 5px 10px 15px;
        border-radius: 7px;
        color: green;
        font-size: 30pt;
        font-family: 'Montserrat';
        font-weight: 700;
        border-top: solid 8px yellow;
    }

    .div-info-autopecas-line {
        width: 100%;
        margin-top: 50px;
        padding-bottom: 50px;
        padding-top: 20px;
    }

    .div-info-autopecas-line22 {
        
    }

    .div-info-autopecas-line-color {
        background-color: rgba(107, 255, 255, 0.151);
    }

    .div-info-autopecas-low-left {
        width: 38%;
        max-width: 38%;
        text-align: left;
        padding-left: 30px;
        font-family: 'Montserrat';
        display: inline-block;
        
        vertical-align: middle;
    }
        h1 {
            font-family: 'Montserrat';
        }
        .list-check-autopecas {
            margin-bottom: 12px;
        }
        .list-check-autopecas > img{
            vertical-align: middle;
            margin-right: 15px;
        }

    .div-info-autopecas-low-right {
        max-width: 55%;
        width: 55%;
        text-align: right;
        align-items: right;
        display: inline-block;
        vertical-align: middle;
        padding-right: 50px;
    }
        
        
        .imgens-lines {
            width: 680px;
            height: 400px;
            vertical-align: middle;
            border-radius: 20px;
            display: inline-block;
            
            object-fit: cover;
        }
        .imgens-lines-box {
            box-shadow: 30px 30px 0px 5px rgba(0, 71, 0, 0.788);
        }
        
        #imgens-lines-pc {
            width: 800px;
            height: 600px;
            object-fit: fill;
            margin-right: -30px;
            border-bottom-right-radius: 40px;
        }

        #imgens-lines-dre {
            max-width: 100%;
            width: auto;
            height: 300px;
            object-fit: fill;
            
        }
        #autopecas-img-abc {
            width: auto;
            height: 400px;
            object-fit: fill;
        }

        .div-check-autopecas-img, .div-check-autopecas-label {
            display: inline-block;
            vertical-align: middle;
        }

        .div-check-autopecas-label {
            width: 90%;
            margin-left: 15px;
        }
    .div-info-autopecas-line-color2 {
        width: 90%;
        text-align: center;
        align-items: center;
        display: inline-block;
        margin-top: -100px;
        background-color: white;
        border-radius: 15px;
        box-shadow: rgb(156, 156, 156) 5px 10px 15px;
    }

    .div-info-autopecas-low-left2 > h1 {
        color: green;
        font-weight: 700;
        font-size: 40pt;
    }

@media screen and (max-width: 900px) {
        .imgens-lines {
            display: none;
        }
        .div-info-autopecas-low-right {
            max-width: 100%;
            width: 100%;
            margin: 0px;
            
            text-align: center;
            align-items: center;
            padding-right: 0;
        }
        .div-info-autopecas-low-left {
            max-width: 100%;
            width: 90%;
        }
        .div-info-autopecas-low-right > img {
            width: 90%;
            height: auto;
            box-shadow: none;
            align-items: center;
            margin-bottom: 10px;
        }
        .div-info-autopecas-low-left {
            max-width: 100%;
            width: 90%;
        }
    
        #autopecas-div-img-titulo {
            width: 95%;
        }
        #imgens-lines-dre {
            width: 100%;
            height: auto;
        }
        #autopecas-img-abc {
            width: 90%;
            height: auto;
            
        }
        #imgens-lines-pc {
            
        }
        
    }
#div-contain-inicial {
    height: 100%;
    background-color: rgb(246, 249, 253);
    padding-top: 10px;
}

#div-sup-inicial {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;
    border-top: 1px solid rgb(190, 190, 190) ;
    border-bottom: 1px solid rgb(190, 190, 190)  ;
}

#logo-inicial {
    width: 120px;
    vertical-align: middle;
}

#logo-inicial-img {
    width: 40px;
    vertical-align: middle;
    
}

#div-button-inicial {
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

#div-button-inicial > button {
    
    width: 90%;
    padding: 20px;
    border-radius: 5px;
    font-size: 13pt;
    border: none;
    background-color: rgb(59, 165, 59);
    color: white;
    font-family: 'Montserrat';
}

#div-pedidos-list {
    background-color: white;
    height: 300px;
    max-height: 300px;
    overflow-y: scroll;
}

.pedido-item:hover{
    background-color: azure;
}


input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
}
input[type=number] { 
   -moz-appearance: textfield;
   appearance: textfield;

}
/*
header {
  --color-background: rgba(112, 105, 4, 0.582);
  background-image: linear-gradient(90deg, rgba(221, 221, 221, 0.438) 0%, rgba(252, 239, 0, 0.85) 10%, rgba(0, 78, 10, 0.85) 100%), url('./imagens/pc-imagem.jpg') ;
  background-size: 100%;
  background-repeat: no-repeat;
 

  height: 800px;
  
 
}
*/

#img-inical {
  width: 100%;
}

#cabecalho-item {
 
}

body {
  overflow: overlay;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(175, 175, 175);
  border-radius: 10px;
}

.div-rodape {
  background-color: rgb(235, 235, 235);
  text-align: center;
  padding: 10px;
  
  font-family: 'Montserrat';
  
}
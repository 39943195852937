#div-supermercado-root {
    text-align: center;
}

    #supermercado-div-img {
        background-image: linear-gradient(90deg, rgba(221, 221, 221, 0.438) 0%, rgba(252, 239, 0, 0.85) 0%, rgba(0, 78, 10, 0.85) 100%), url('../imagens/supermercado.jpg') ;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 250px;
    }
    #supermercado-div-img-titulo {
        margin-top: -80px;
        background-color: white;
        width: 700px;
        text-align: center;
        display: inline-block;
        box-shadow: rgb(156, 156, 156) 5px 10px 15px;
        border-radius: 7px;
        color: green;
        font-size: 30pt;
        font-family: 'Montserrat';
        font-weight: 700;
        border-top: solid 8px yellow;
    }

    .div-info-supermercado-line {
        width: 100%;
        margin-top: 50px;
        padding-bottom: 50px;
        padding-top: 20px;
    }

    .div-info-supermercado-line-color {
        background-color: rgba(107, 255, 255, 0.151);
    }

    .div-info-supermercado-low-left {
        width: 38%;
        max-width: 38%;
        text-align: left;
        padding-left: 30px;
        font-family: 'Montserrat';
        display: inline-block;
        
        vertical-align: middle;
    }
        h1 {
            font-family: 'Montserrat';
        }
        .list-check-supermercado {
            margin-bottom: 12px;
        }
        .list-check-supermercado > img{
            vertical-align: middle;
            margin-right: 15px;
        }

    .div-info-supermercado-low-right {
        max-width: 55%;
        width: 55%;
        text-align: right;
        align-items: right;
        display: inline-block;
        vertical-align: middle;
        padding-right: 50px;
    }
        
        
        .imgens-lines {
            width: 680px;
            height: 400px;
            vertical-align: middle;
            border-radius: 20px;
            display: inline-block;
            
            object-fit: cover;
        }
        .imgens-lines-box {
            box-shadow: 30px 30px 0px 5px rgba(0, 71, 0, 0.788);
        }
        
        #imgens-lines-pc {
            width: 800px;
            height: 600px;
            object-fit: fill;
            margin-right: -30px;
            border-bottom-right-radius: 40px;
        }

        #imgens-lines-dre {
            width:  100%;
            height: 300px;
            object-fit: fill;
        }
        #supermercado-img-abc {
            width: auto;
            height: 400px;
            object-fit: fill;
        }

        .div-check-supermercado-img, .div-check-supermercado-label {
            display: inline-block;
            vertical-align: middle;
        }

        .div-check-supermercado-label {
            width: 90%;
            margin-left: 15px;
        }

@media screen and (max-width: 900px) {
    .imgens-lines {
        display: none;
    }

    .div-info-supermercado-low-right {
        max-width: 100%;
        width: 100%;
        margin: 0px;
        
        text-align: center;
        align-items: center;
        padding-right: 0;
    }
    .div-info-supermercado-low-left {
        max-width: 100%;
        width: 90%;
    }

    #supermercado-div-img-titulo {
        width: 95%;
    }
    .div-info-supermercado-low-right > img {
        width: 90%;
        height: auto;
        box-shadow: none;
        align-items: center;
        margin-bottom: 10px;
    }
    .div-info-supermercado-line {
        padding: 0px;
        max-width: 100%;
        
    }
    #imgens-lines-dre {
        width: 100%;
        height: auto;
    }
    #supermercado-img-abc {
        width: 90%;
        height: auto;
        
    }
    #imgens-lines-pc {
        
    }
    #div-pc-img > img {
        width: 90%;
        height: auto;
        border-bottom-right-radius: 0px;
    }
}
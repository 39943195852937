#div-img-login {
    width: 100%;
    height: 400px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    text-align: center;
    align-items: center;
    
}

#bg-login {
    fill: white;
    width: 100%;
    transform: rotateX(180deg);
    position: relative;
    top: -20px;
    
}
#div-sup-login {
    margin-top: -100px;
    position: relative;
    top: -10px
}

#logo-mega {
    
    margin-top: 10px;
    height: 200px;
}

#div-inputs-login {
    background-color: white;
    height: 300px;
    padding: 50px;
}

#p-titulo {
    font-family: 'Montserrat';
    font-size: 15pt;
    font-weight: 800;
    color: white;
}

html { height:100%; }

.input-mobile {
    font-size: 13pt;
    border: none;
    width: 100%;
    border-bottom: 1px rgb(165, 165, 165) solid;
    font-family: 'Montserrat';
    padding-bottom: 10px;
    outline: none;
    
}

.input-mobile:focus{
    border-bottom: 2px rgb(0, 73, 184) solid;
    font-weight: 500;
}



.p-input {
    font-family: 'Montserrat';
}

#button-login {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(59, 165, 59);
    color: white;
    font-family: 'Montserrat';
    font-weight: 600;
    border: none;
    
}
@media screen and (min-width: 1000px){
    #contain-login {
        width: 500px;
        position: absolute;

        top: 50%;

        left: 50%;

        transform: translate(-50%, -50%);
        background-color: red;
    }
    #div-img-login {
        height: 0px;
        background-size: cover;
    }
    #bg-login {
        fill: black;
        margin-top: -200px;
        display: none;
    }
    #div-login {
        background-color: rgb(116, 116, 116);
        height: 100vh;
        width: 100%;
    }
}
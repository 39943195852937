
nav {
    position: fixed;
    top: 0;
    width: 100%;
    
}


#div-root-cabecalho {
    width: 100%;
    /*background-color: rgb(133, 148, 0);*/
    /*border-bottom: solid 0.5px rgba(75, 75, 75, 0.836);*/
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
    padding-top: 10px;
    padding-bottom: 10px;

}
    #div-contem-img-logo {
        width: 560px;
        margin-left: 5%;
        display: inline-block;
    }
    
    #img-pring-div {
        width: 100px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 50px;
    }

    #img-nome-div {
        width: 200px;
        display: inline-block;
        vertical-align: middle;
    }

    #div-menu-hambuger {
        float: right;
        margin-top: 5px;
        margin-right: 5px;
        
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 2px;
        display: none;
        text-align: left;
    }
        #div-menu-hambuger > input {
            display: none;
        }
        
        #div-menu-hambuger > label {
            font-size: 30pt;
            color: white;
        }
        #icon-menu-hamburger {
            display: none;
        }

        @media screen and (max-width: 1250px) {
            #icon-menu-hamburger {
                display: flex;
            }
            #div-menu-hambuger {
                display: flex;
            }
            #options-menu {
                display: flex;
                width: 100%;
                background-color: aqua;
            }
            
        }
        
    

    #div-menu-root {
        display: inline-block;
        padding: 5px;
    }
        

        .options-menu-itens {
            
            font-weight: 600;
            
        }

        @media screen and (max-width: 1250px) {
            #div-menu-root {
                width: 100%;
                display: none;
            }
            
        }

        
    #div-menu-root-hambuguer {
        display: none;
        width: 95%;
        background-color: green;
        text-align: center;
        margin-left: 4%;
        border-radius: 10px;
        
    }
        @media screen and (max-width: 1250px) {
            #div-menu-root {
                width: 100%;
                display: none;
            }
            #div-menu-root-hambuguer {
                display: block;
            }
            #options-menu-hambuguer {
                display: none;
            }
            #div-contem-img-logo {
                width: 300px;
                margin-left: 5px;
                display: inline-block;
            }
            #img-pring-div {
                width: 80px;
                display: inline-block;
                vertical-align: middle;
                margin-left: 20px;
            }
        }
        #options-menu-hambuguer > a {
            display: block;
            text-align: left;
        }
        #div-cabecalho-hamb > a{
            display: block;
            text-align: left;
            color: white;
            font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
            text-decoration: none;
            font-weight: 600;
            margin: 5px;
        }
        #div-cabecalho-hamb > a:hover {
            cursor: pointer;
        color: rgba(24, 24, 24, 0.74);
        -webkit-transition: color 1000ms linear;
        -ms-transition: color 1000ms linear;
        transition: color 1000ms linear;
        }
        #div-cabecalho-hamb {
            margin-left: 30px;
        }
        #options-menu-hambuguer {
            padding: 10px;
            
        }
    
    .menu-div {
        
        width: 40%;
        text-align: right;
       
    }
    .options-menu-itens {
        color: white;
        
        font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
        margin: 10px;
    }
    .options-menu-itens:hover {
        
        cursor: pointer;
        color: rgba(24, 24, 24, 0.74);
        -webkit-transition: color 1000ms linear;
        -ms-transition: color 1000ms linear;
        transition: color 1000ms linear;
    }
    .options-menu-itens:link {
        text-decoration: none;
      }
    .submenu-options-menu-itens {
        position: relative;
        
    }
    .options-menu-itens-div {
        display: inline-block;
    }
    .options-submenu {
        position: absolute;
       display: none;
    }
    .options-submenu > a {
        cursor: pointer;
        display: block;
        text-align: left;
        padding: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .options-submenu > a:link {
        text-decoration: none;
        color: black;
    }
    .options-submenu-show-true {
        display: block;
        background-color: white;
        color: black;
        padding: 10px;
        font-family: 'Montserrat';
        margin-left: 10px;
        border-top: solid 5px yellow;
        font-weight: 500;
        font-size: 11pt;
        box-shadow: rgb(156, 156, 156) 5px 5px 15px;
    }
    
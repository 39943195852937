#corpo-div {
    --color-background: rgba(112, 105, 4, 0.582);
    background-image: linear-gradient(90deg, rgba(221, 221, 221, 0.438) 0%, rgba(252, 239, 0, 0.85) 10%, rgba(0, 78, 10, 0.85) 100%), url('../imagens/pc-imagem.jpg') ;
    background-size: cover;
    background-repeat: no-repeat;
    /*padding-bottom: 75px;*/
    min-height: 100vh;

}

#div-corpo-superior {
    padding-top: 18%;
    padding-left: 20%;
    max-width: 800px;
}
    #p-nome-empresa {
        
        font-weight: 800;
        font-size: 13pt;
        font-family: 'Montserrat';
        letter-spacing: 5px;

         background-image: linear-gradient(90deg, rgb(4, 43, 0) 0%, rgb(79, 214, 0) 30%);
         color: black;

        background-clip: text;
        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
    }

    #div-corpo-superior > label{
        color: white;
        font-weight: 700;
        font-size: 28pt;
        font-family: 'Montserrat';

    }
        span {
            color: #ff5100;
            font-weight: label;
            font-weight: 700;
        }   

    #p-inferior-div-corpo-superior {
        display: block;
        color: white;
        font-weight: 500;
        font-size: 13pt;
        font-family: 'Roboto';
        
    }

    #div-titulo-fone {
        font-family: 'Poppins';
        color: white;
        border: 1px solid green;
        display: inline-block;
        padding: 10px;
        font-weight: 700;
        letter-spacing: 1px;
        box-shadow: green 0px 0px 15px;
        border-radius: 7px;
        -webkit-transition: background-color 500ms linear, color 500ms linear, box-shadow 500ms linear, border-color 500ms linear;
        -ms-transition: background-color 500ms linear, color 500ms linear, box-shadow 500ms linear, border-color 500ms linear;
        transition: background-color 500ms linear, color 500ms linear, box-shadow 500ms linear, border-color 500ms linear;
        font-size: 15pt;
    }
        #icon-whats-img {
            width: 40px;
            vertical-align: middle;
            margin-left: 5px;
            margin-top: -5px;
            margin-bottom: -5px;
        }

    #div-titulo-fone:hover {
        background-color: rgba(255, 255, 0, 0.384);
        color: green;
        cursor: pointer;
        box-shadow: white 0px 0px 15px;
        border-color: white;
    }

#div-corpo-descricional {
    display: block;
    align-items: left;
    text-align: left;
    margin-left: 20%;
    padding-bottom: 20px;
    padding-top: 10px;
}

#p-titulo-superior-descricional {

    font-size: 25pt;
    font-weight: 750;
    color: rgb(38, 119, 0);
    font-family: 'Nunito Sans';
}
#linha-divisao {
    
    height: 1px;
    border: 0;
    border-top: 2.5px solid #ff5100;
    border-radius: 2px;
    padding: 0;
    width: 10%;
    text-align: left;
    align-items: left;
    float: left;
    display: block;
    /*margin-right: 90%;*/
}

#div-p-titulo-superior-descricional2{
    display: inline-block;
    width: 100%;
}

    #p-titulo-superior-descricional2 {
        display: block;
        font-size: 15pt;
        font-weight: 600;
        color: white;
        font-family: 'Nunito Sans';
    }
    @media screen and (max-width: 900px) {
        #div-corpo-superior {
            padding-top: 150px;
            padding-left: 20px;
            padding-right: 10px;
        }
        #div-corpo-descricional {
            margin-left: 20px;
            padding-right: 10px;
        }
    }
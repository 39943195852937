#div-root-quem-somos {
    text-align: center;
}
    #div-img-quem-somos {
        background-image: linear-gradient(90deg, rgba(221, 221, 221, 0.438) 0%, rgba(252, 239, 0, 0.85) 0%, rgba(0, 78, 10, 0.85) 100%), url('../imagens/quem-somos.jpg') ;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 70vh;
    }
        #div-label-quem-somos-img{
            padding-top: 150px;
            text-align: center;
        }
            #div-label-quem-somos-img > label {
                color: white;
                font-size: 30pt;
                font-family: 'Montserrat';
                font-weight: 700;
            }

    #div-texto-sobre-nos {
        display: inline-block;        
        width: 700px;
        max-width: 800px;
        text-align: center;
        align-items: center;
        height: 400px;
        background-color: white;
        margin-top: -180px;
        border-radius: 7px;
        box-shadow: rgb(156, 156, 156) 5px 10px 15px;
        padding: 50px;
        margin-bottom: 20px;
    }
        #div-texto-sobre-nos > h1 {
            color: green;
            font-family: 'Montserrat';
            font-weight: 900;
            font-size: 25pt;
            padding-bottom: 10px;
        }
        #div-texto-sobre-nos > hr {
            width: 130px;
            
        }
        #div-texto-sobre-nos > p {
            padding-left: 80px;
            padding-right: 80px;
            font-size: 15pt;
            font-family: 'Nunito Sans';
            word-spacing: 2px;
            line-height: 27px;
            font-weight: 500;
            color: rgb(114, 114, 114);
        }

        @media screen and (max-width: 900px) {
            #div-texto-sobre-nos {
                width: 70%;
                max-width: 90%;
                height: auto;
            }
            #div-texto-sobre-nos > p {
                padding-left: 30px;
                padding-right: 30px;
            }
           
        }